import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone/index';

import cloneDeep from 'loadsh/cloneDeep';
import { fixStructBase64, getMaxLenghCuentasByType, getOptionsToProduct, getProductInfo } from '../../../util/Util';
import { OTHER_BANKS, userDefault } from '../../../util/Constant';
import { menuCode, policyFilterMessage } from '../../shared/constants/Strings';
import { Coverage } from '../../shared/constants/Coverage';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { formCmp } from '../../shared/constants/Forms';
import GenericConstancyServices from '../../shared/services/GenericConstancyServices';
import FormsService from '../../shared/services/FormsService';

import { Routes } from '../../../routes';
import { PSInfoProblems } from '../../components/molecules/index';
import { GAProvider } from '../../components/PSAnalytics';
import PSBodyProduct from '../../components/PSBodyProduct';

import './index.scss';

const img = 'product-rrvv';
const imgMobile = 'product-rrvv-mobile';
moment.locale('es');
const headerTableFather = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Fecha de inicio' },
];

export default function HomeServices(props) {
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCode, setCurrentCode] = useState(null);
  const ga = React.useContext(GAProvider);
  const [options, setOptions] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const attributes = userInfo && userInfo.attributes ? userInfo.attributes : userDefault;
  const fullName = `${attributes?.middleName} ${attributes?.familyName} ${attributes?.givenName} `;
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const {
      product: menuId,
      productDescription: menuDescription,
      policies
  } = getProductInfo(userInfo?.productsDetail, menuCode.RENTA_VITALICIA);

  const [fileBase64, setFileBase64] = useState({});
  const [form, setForm] = useState({
    CMP: cloneDeep(formCmp),
  });
  const [isPensioner, setIsPensioner] = useState(false);
  const policiesMessage = policyFilterMessage.rentasVitalicias;

  const getOptionsAvailable = () => {
    const option = [];
    if (process.env.REACT_APP_PERMISSION_PRODUCT_RRVV_BOLETAS === 'true') {
      option.push('BLT');
    }
    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_RRVV_CONSTANCIA_PENSIONISTA === 'true' &&
      isPensioner
    ) {
      option.push('CPS');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_RRVV_CRONOGRAMA_PAGO === 'true') {
      option.push('CP');
    }
    if (process.env.REACT_APP_PERMISSION_PRODUCT_RRVV_CAMBIO_MODALIDAD_PAGO === 'true') {
      option.push('CMP');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_RRVV_DESCARGA_POLIZA === 'true') {
      option.push('DP');
    }
    setOptions(option);
  };
  const clearForms = (code) => {
    if (code) {
      form[code].forEach((item) => {
        if (!item.readOnly) {
          if (item.field !== 'cobrante' && item.field !== 'pensionista') {
            // eslint-disable-next-line no-param-reassign
            item.value = '';
            // eslint-disable-next-line no-param-reassign
            item.error = '';
          }
        }
      });
      setForm({ ...form });
    }
  };

  const onchangeModePage = (e, index, code) => {
    const value = e.target ? e.target.value : e;
    const { maxLength } = form[code][index];
    if (maxLength) {
      if (value.length <= maxLength) {
        form[code][index].value = value;
        setForm({ ...form });
      }
    } else {
      form[code][index].value = value;

      if (form[code][index].field === 'banco') {
        let indexUpdate = 7;

        if (value !== 'otros') {
          form[code] = form[code].filter((item) => {
            return item.field !== 'otroBanco';
          });
          // Eliminar CCI
          form[code][8].onlySelect = true;
          form[code][8].required = false;
          // form[code][8].required = true; // show CCI
        } else {
          form[code].splice(5, 0, {
            field: 'otroBanco',
            label: 'Nombre del banco:',
            type: 'select',
            value: '',
            required: true,
            options: [...OTHER_BANKS],
          });
          indexUpdate += 1;
          form[code][indexUpdate + 1].required = true;
          form[code][indexUpdate + 1].onlySelect = false; // hidden CCI
        }
        form[code][indexUpdate + 1].value = '';
      }

      if (
        form[code][index].field === 'otroBanco' ||
        form[code][index].field === 'banco' ||
        form[code][index].field === 'tipoDeCuenta'
      ) {
        const indexAccount = form[code].findIndex((item) => item.field === 'account');
        const bankG = form[code].find((item) => item.field === 'banco')?.value;
        const typeAccount = form[code].find((item) => item.field === 'tipoDeCuenta')?.value;
        const bank =
          bankG === 'otros' ? form[code].find((item) => item.field === 'otroBanco')?.value : bankG;

        form[code][indexAccount].value = '';
        form[code][indexAccount].error = '';
        form[code][indexAccount].maxLength = getMaxLenghCuentasByType(bank, typeAccount); // todo replace dynamic
      }

      setForm({ ...form });
    }
  };

  const getPensionerConstancy = async ({ nroPolicy: policy }) => {
    try {
      setLoadingOption(true);
      const headers = {
        policy,
      };
      const { data, status } = await GenericConstancyServices.responseDocumentPensioner(headers);
      if (data && status?.id === 200) {
        const fixBase64 = fixStructBase64(data[0]?.document);
        setFileBase64.CPS = fixBase64;
        setFileBase64({ ...setFileBase64 });
        setLoadingOption(false);
      } else {
        setFileBase64.CPS = null;
        setFileBase64({ ...setFileBase64 });
        setLoadingOption(false);
      }
    } catch (error) {
      setFileBase64.CPS = null;
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
      console.error(error);
    }
  };

  const validatePensioner = async (nroPolicy) => {
    try {
      const { data, status } = await FormsService.validateFormByPolicy(nroPolicy, menuId);
      if (data && status && status.id === 200) {
        const {
          receiver: { document },
        } = data;
        setIsPensioner(userInfo.document === document.number);
      } else {
        setIsPensioner(false);
      }
    } catch (error) {
      setIsPensioner(false);
    }
  };

  useEffect(() => {
    getOptionsAvailable();
  }, [isPensioner]);

  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];

      if (currentCode === 'CPS') {
        getPensionerConstancy(policyCurrent);
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    if (currentIndex !== -1) {
      const { nroPolicy } = policies[currentIndex];
      form.CMP[0].value = nroPolicy;
      form.CMP[1].value = fullName;
      form.CMP[2].value = fullName;
      setForm({ ...form });
      validatePensioner(nroPolicy);
    }
  }, [currentIndex]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto Rentas Vitalicias`,
      action: 'click',
      label: 'El usuario visitó la página de Rentas Vitalicias',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="home-rrvv-container">
      {process.env.REACT_APP_NO_AVAILABLE_PRODUCTOS_RRVV === 'true' ? (
        <PSInfoProblems />
      ) : (
        <PSBodyProduct
          questions={FrequentQuestions.RENTA_VITALICIA}
          coverages={Coverage.RENTA_VITALICIA}
          linkToSchedule={Routes.HOME_PRODUCT_RRVV_SCHEDULE}
          linkToTicket={Routes.HOME_PRODUCT_RRVV_TICKET}
          img={img}
          imgMobile={imgMobile}
          descriptionHeader="Rentas Vitalicias"
          iconHeader="productoRenta"
          loading={loading}
          options={getOptionsToProduct(options)}
          fileBase64={fileBase64}
          data={policies}
          headerTable={headerTableFather}
          form={form}
          // eslint-disable-next-line no-shadow
          updateForm={(form) => setForm({ ...form })}
          onSelectItem={(item) => setCurrentIndex(item)}
          onSetCurrentCodeForm={setCurrentCode}
          onChangeModePage={onchangeModePage}
          branchId={policies[currentIndex]?.nbranch || ''}
          productId={policies[currentIndex]?.idProduct || ''}
          menuId={menuId}
          menuDescription={menuDescription}
          clearForms={clearForms}
          useDigitalPolicy
          loadingOption={loadingOption}
          policiesMessage={policiesMessage}
          {...props}
        />
      )}
    </div>
  );
}
