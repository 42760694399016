import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';

import PSLoginContainer from '../../components/PSLoginContainer';
import PSHeaderLogin from '../../components/PSHeaderLogin';
import PSButton from '../../components/PSButton';
import PSBox from '../../components/PSBox';
import PSLabel from '../../components/PSLabel';
import PSLogingMessageValidation from '../../components/PSLogingMessageValidation';
import PSTimerValidation from '../../components/PSTimerValidation';
import PSParagraph from '../../components/PSParagraph';
import PSError from '../../components/PSError';
import PSMessageResponse from '../../components/PSMessageResponse';
import { Strings } from '../../shared/constants/Strings';
import { removeDashInput } from '../../../util/Util';
import PSFormSelectValidation from '../../components/PSFormSelectValidation';
import FormsService from '../../shared/services/FormsService';
import ValidateOTPService from '../../shared/services/ValidateOTPService';

export default function OTPValidation({
  onCloseModal,
  onValid,
  url,
  dataSave,
  headerParams,
  onSendValidation,
  onSendErrorCode,
}) {
  const { codeSent, signUp: user, userInfo, attributesCore } = useSelector((state) => state.auth);
  const [validationType, setValidationType] = useState(user.validationType);
  const [sendValidation, setSendValidation] = useState(false);
  const [confirmValidation, setConfirmValidation] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [codeValidation, setCodeValidation] = useState(null);
  const [error, setError] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [validationCode, setValidationCode] = useState();
  const [exceedAttempts, setExceedAttempts] = useState();
  const [sendCode, setSendCode] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const documentID = userInfo.documentType + userInfo.document;
  const showSMS = process.env.REACT_APP_SHOW_OTP_SMS === 'true';

  const onSelectValidation = (value) => {
    setValidationType(value);
  };

  const onButtonClick = () => {
    if (confirmValidation) {
      sendCodeValidation();
    } else {
      sendToValidation();
    }
  };

  const sendToValidation = () => {
    if (!validationType) {
      setError('Seleccione una opción');
      return;
    }
    setError(null);
    if (!sendValidation) {
      sendOTPForm();
    } else if (!confirmValidation) {
      setLastPage(true);
      setConfirmValidation(true);
    }
  };

  const sendOTPForm = async () => {
    try {
      setLoading(true);
      const type = validationType === 'email' ? 10 : 20;
      const response = await ValidateOTPService.responsePostValidate(
        url,
        dataSave,
        type,
        null,
        headerParams
      );
      if (
        response.status &&
        response.status.id === 200 &&
        response.status.name === 'STATE CHANGE IN PROGRESS'
      ) {
        setSendValidation(true);
        onSendValidation(validationType);
      } else {
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const validateOTPForm = async (code) => {
    try {
      setLoading(true);
      const type = validationType === 'email' ? 10 : 20;
      const response = await ValidateOTPService.responsePostValidate(
        url,
        dataSave,
        type,
        code,
        headerParams
      );

     if (response.status) {
       const { id, name } = response.status;
       switch (id) {
         case 201:
         case 200:
           onValid(true, response);
           break;
         case 204:
           setInvalidCode(true);
           onSendErrorCode(validationType, response?.data);
           if (name === 'LIMIT EXCEEDED') setExceedAttempts(true);
           break;
         default:
           onValid(false, response);
       }
     } else {
       onValid(false);
     }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setInvalidCode(true);
      onSendErrorCode(validationType);
      onValid(false);
      console.error(e);
    }
  };

  const sendCodeValidation = () => {
    if (!removeDashInput(codeValidation)) {
      setError('Ingrese el codigo');
    }
    setError(null);
    validateOTPForm(removeDashInput(codeValidation));
  };

  const forwardCode = () => {
    setSendCode(true);
    setDisabledButton(false);
    sendOTPForm();
  };

  const getFooterMenssage = () => {
    if (confirmValidation) {
      return '';
    }

    if (sendValidation) {
      return validationType === 'phoneNumber' ? Strings.INFO_PHONE : Strings.INFO_EMAIL;
    }

    return Strings.INFO_PASSWORD;
  };

  const onClose = () => {
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const isButtonDisabled = () => {
    return disabledButton ||
      validationType === undefined ||
      (lastPage && codeValidation === null) ||
      codeValidation === ''
      ? 'button-disabled'
      : '';
  };

  useEffect(() => {
    if (codeSent) {
      if (sendCode) {
        setSendCode(false);
      }
      setSendValidation(true);
    }
  }, [codeSent]);

  return attributesCore.email || attributesCore.phone ? (
    <div className="sign-up-validation">
      {!validationCode && !exceedAttempts && (
        <PSLoginContainer className="user-container" onClose={onClose}>
          <PSHeaderLogin className="user-header-login" title="Validar solicitud" icon="user" />

          <div className="user-body-validation">
            {!sendValidation && (
              <PSFormSelectValidation
                className="user-select-validation"
                onChange={onSelectValidation}
                value={validationType}
              />
            )}

            {sendValidation && !confirmValidation && (
              <PSLogingMessageValidation
                className="user-message-validation"
                type={validationType}
              />
            )}

            {confirmValidation && (
              <PSTimerValidation
                className="user-timer-validation"
                onChange={(e) => {
                  setInvalidCode(false);
                  setCodeValidation(e.target.value);
                }}
                value={codeValidation}
                setDisabledProp={(value) => setDisabledButton(value)}
                refresh={sendCode}
                error={confirmValidation && invalidCode}
              />
            )}
            
            {error && <PSError>{error}</PSError>}
            {confirmValidation && invalidCode && (
              <PSError>
                *El código de verificación que ingresaste no es correcto vuelve a solicitar el envío
              </PSError>
            )}
          </div>
          <PSBox className="container-footer-buttons">
            <PSButton
              onClick={onButtonClick}
              disabled={
                confirmValidation ? codeValidation == null || codeValidation === '' : disabledButton
              }
              className={isButtonDisabled()}
              loading={loading}
            >
              Aceptar
            </PSButton>
          </PSBox>
          {confirmValidation && (
            <PSBox className="container-footer-buttons">
              <PSLabel>¿No recibiste el código de verificación?</PSLabel>
              <PSButton
                onClick={forwardCode}
                loading={sendCode && loading}
                className="btn-secondary"
              >
                Reenviar
              </PSButton>
            </PSBox>
          )}
          <PSBox className="user-footer-message">
            <PSParagraph>{getFooterMenssage()}</PSParagraph>
          </PSBox>
        </PSLoginContainer>
      )}

      {validationCode && <PSMessageResponse title="Validación exitosa" icon="successFace" />}

      {exceedAttempts && (
        <PSMessageResponse
          onClose={onCloseModal}
          title={Strings.PASSWORD_EXCEEDED_TRY_TITlE}
          icon="failed"
        >
          {Strings.PASSWORD_EXCEEDED_TRY}
        </PSMessageResponse>
      )}
    </div>
  ) : (
    <PSMessageResponse icon="face" onClose={onCloseModal}>
      {Strings.USER_INCOMPLETE}
    </PSMessageResponse>
  );
}
