import Axios from 'axios';
import { Api, ApiBusiness, doPost, doPostUnauthorized, doPostOtp, doPostValidateOtp } from './Api';
import { getValueDocument } from '../../../util/Util';
import { STORAGE_TOKEN_APP } from '../../../util/Constant';
import { AuthValues } from '../constants/Strings';

export default class AuthService {
  static async validateUserDigitalStatus(documentId, documentType, validCaptcha) {
    const data = {
      identityDocument: {
        documentNumber: documentId,
        documentType: {
          id: getValueDocument(documentType),
        },
      },
      captchaCode: validCaptcha,
    };
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const response = await doPost(
      `${process.env.REACT_APP_ONBORDING}/digital-activation-status`,
      data,
      token
    );
    const dataResponse = response.data;

    if (dataResponse && dataResponse.status.id === 204) {
      return 'INVALID_USER';
    }
    if (dataResponse && dataResponse.status.id === 423) {
      return 'BLOCKED_USER';
    }
    return 'VALID_USER';
  }

  // deprecate
  static async validateCognito(documentId, documentType) {
    const response = await Api.get(
      `/user-data-authentications/v0/users/${documentType}${documentId}`
    );
    if (response.data.statusCode === 500) {
      return 'INVALID_USER';
    }

    return 'VALID_USER';
  }

  static async validateUserConditions(payload) {
    const showSMS = process.env.REACT_APP_SHOW_OTP_SMS === 'true';
    const data = {
      identityDocument: {
        documentNumber: payload.documentId,
        documentType: {
          id: getValueDocument(payload.documentType),
        },
      },
    };

    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const response = await doPost(
      `${process.env.REACT_APP_ONBORDING}/contact-details-enrolment`,
      data,
      token
    );

    const dataResponse = response?.data || {};
    if (dataResponse.status && dataResponse.status?.id === 500) {
      return ['ERROR_USER'];
    }
    const validation = showSMS
      ? dataResponse.contactPhone?.phone || dataResponse.contactEmail?.email
      : dataResponse.contactEmail?.email;

    if (!dataResponse.status && validation) {
      let userValidation = {
        checkValue: dataResponse.isAdvertisingTermsAccepted,
        email: dataResponse.contactEmail?.email,
      };

      if (showSMS) {
        userValidation = { ...userValidation, phoneNumber: dataResponse.contactPhone?.phone };
      }

      return ['VALID_USER', userValidation];
    }

    if (
      dataResponse.status &&
      dataResponse.status?.id === 204 &&
      dataResponse.status?.name === AuthValues.NO_CUSTOMER
    ) {
      return ['INVALID_USER'];
    }
    if (
      dataResponse.status?.id === 409 &&
      dataResponse.status?.name === AuthValues.CUSTOMER_INVALID_POLICIES
    ) {
      return ['USER_INVALID_POLICIES'];
    }

    if (
      dataResponse.status?.id === 409 &&
      dataResponse.status?.name === AuthValues.INVALID_CONTACT
    ) {
      return ['NO_DATA_USER'];
    }
    return ['NO_DATA_USER'];
  }

  // deprecate
  static async isUserValid(payload) {
    const response = await Api.post('/user-data-authentications/v0/enrollments', {
      documentID: payload.documentType + payload.documentId.toString(),
      birthdate: payload.date,
    });

    if (response.data.statusCode === 500) {
      return ['INVALID_USER'];
    }

    if (
      response.data.statusCode !== 500 &&
      (response.data.data.email || response.data.data.phoneNumber)
    ) {
      return ['VALID_USER', response.data.data];
    }

    if (response.data.statusCode === 200 && response.data.data === 'USER_INVALID_POLICIES') {
      return ['USER_INVALID_POLICIES'];
    }

    if (response.data.statusCode !== 500 && response.data.data.response) {
      return ['ERROR_USER'];
    }

    if (response.data.statusCode === 200 && response.data.data === 'USER_INVALID_POLICIES') {
      return ['USER_INVALID_POLICIES'];
    }

    if (response.data.statusCode === 200 && response.data.data === 'USER_INVALID_ACCESS') {
      return ['USER_INVALID_ACCESS'];
    }

    return ['NO_DATA_USER'];
  }

  static async sendValidationOtp(payload) {
    const data = {
      identityDocument: {
        idValidation : payload.idValidation ,
        documentNumber: payload.documentId,
        documentType: {
          id: getValueDocument(payload.documentType),
        },
      },
    };
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const type = payload.validationType === 'email' ? 10 : 20;
    const response = await doPostOtp(
      `${process.env.REACT_APP_ONBORDING}/digital-activation/init`,
      data,
      token,
      type
    );
    return response.data;
  }

  // deprecate
  static async sendVerificationCode(payload) {
    const response = await Api.post('/user-data-authentications/v0/enrollments/init', {
      documentID: payload.documentType + payload.documentId.toString(),
      sendOtp: payload.validationType === 'email',
    });

    return response.data;
  }

  static async validateOTPChallenge(payload) {
    const data = {
      identityDocument: {
        idValidation: payload.idValidation,
        documentNumber: payload.documentId,
        documentType: {
          id: getValueDocument(payload.documentType),
        },
      },
    };
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const type = payload.validationType === 'email' ? 10 : 20;

    const response = await doPostValidateOtp(
      `${process.env.REACT_APP_ONBORDING}/digital-activation/check`,
      data,
      token,
      type,
      payload.code
    );
    const dataResponse = response.data;

    if (dataResponse.status.id === 204 && dataResponse.status.name === 'LIMIT EXCEEDED') {
      return 'TRIES_EXCEEDED';
    }

    if (dataResponse.status.id === 204 && dataResponse.status.name === 'UNSUCCESS VALIDATION') {
      return 'INVALID_CODE';
    }

    if (dataResponse.status.id === 500) {
      return 'SERVER_ERROR';
    }

    return 'VALID_CODE';
  }

  // deprecate
  static async validateVerificationCode(payload) {
    const response = await Api.post('/user-data-authentications/v0/enrollments/check', {
      documentID: payload.documentType.toString() + payload.documentId.toString(),
      otp: payload.code,
    });

    if (response.data.statusCode === 500) {
      return 'TRIES_EXCEEDED';
    }

    if (
      response.data.statusCode === 200 &&
      response.data.data.response === 'El codigo no es correcto'
    ) {
      return 'INVALID_CODE';
    }

    return 'VALID_CODE';
  }

  static async finalizeProcess(payload, code) {
    const data = {
      'customer-id': payload.documentType + payload.documentId.toString(),
      isAdvertisingTermsAccepted: payload.pdpNetPrivada,
      isAcceptDataTeatmentPolicyAccepted: true,
      password: payload.password,
    };

    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const type = payload.validationType === 'email' ? 10 : 20;
    const response = await doPostValidateOtp(
      `${process.env.REACT_APP_ONBORDING}/digital-activation/finalize`,
      data,
      token,
      type,
      code
    );
    return response.data;
  }

  // deprecate
  static async createCognitoUser(payload, code) {
    const response = await Api.post('/user-data-authentications/v0/enrollments/finalize', {
      documentID: payload.documentType + payload.documentId.toString(),
      otp: code,
    });
    return response.data;
  }

  // deprecate
  static async changePassword(payload, code) {
    const response = await Api.post('/user-data-authentications/v0/users/update', {
      documentID: payload.documentType + payload.documentId.toString(),
      password: payload.password,
      pdpNetPrivada:
        payload.pdpNetPrivada !== null ? payload.pdpNetPrivada.toString() : payload.pdpNetPrivada,
      otp: code,
    });

    return response.data;
  }

  static async recoverPassword(payload, code) {
    const data = {
      'customer-id': payload.documentType + payload.documentId.toString(),
      password: payload.password,
    };

    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const type = payload.validationType === 'email' ? 10 : 20;

    const response = await doPostValidateOtp(
      `${process.env.REACT_APP_ONBORDING}/recover-password`,
      data,
      token,
      type,
      code
    );

    return response.data;
  }

  static async changePasswordBusiness({
    documentType,
    documentId,
    nombre,
    password,
    email,
    pdpNetPrivada,
  }) {
    const response = await doPost(
      `${process.env.REACT_APP_BASE_URL_NET}/nntp-cognito-empresa-api/v0/enrollments/first-login`,
      {
        id: documentType + documentId?.toString(),
        nombre,
        password,
        email,
        pdpNetPrivada: pdpNetPrivada?.toString(),
      }
    );
    return response.data;
  }

  static async registerBusiness(payload) {
    const response = await ApiBusiness.post(
      '/business-user-data-authentications/v0/enrollments/init',
      {
        documentID: payload.document_type + payload.documentID,
        contactDocumentID: payload.documentType + payload.contactDocument.toString(),
        contactName: payload.name,
        contactLastName: payload.lastName,
        contactLastName2: payload.lastName2,
        contactEmail: payload.contactEmail,
        contactPhone: payload.contactPhone,
        contactPhone2: payload.contactPhone2,
        indPDP: payload.indPDP,
        contactRole: payload.role,
        date: payload.date,
        documentPath: payload.documentPath,
      }
    );
    return response.data;
  }

  static async getUrlDocuments(structToGetUrlFile) {
    const response = await ApiBusiness.get(
      `/business-user-data-authentications/v0/enrollments/${structToGetUrlFile}`
    );

    return response.data;
  }

  static async uploadFile(url, data, type) {
    const config = {
      method: 'put',
      url,
      headers: {
        'content-type': `${type}`,
      },
      data,
    };

    const response = await Axios(config);
    return response;
  }

  static async createCognitoBusiness(documentId) {
    const response = await ApiBusiness.post(
      '/business-user-data-authentications/v0/enrollments/finalize',
      {
        documentID: documentId,
      }
    );
    return response;
  }

  static async updateUser(payload) {
    const response = await Api.post('/user-data-authentications/v0/users/update', payload);

    return response.data;
  }

  static async getTokenApp() {
    const data = {
      authentication: {
        applicationId: '21200001',
        authenticationType: '00',
        userData: {
          user: 'NETPRIVAPUBMAQ',
          password: `${process.env.REACT_APP_PASS_APP}`,
        },
      },
    };
    const response = await doPostUnauthorized(`${process.env.REACT_APP_AUTHENTICATION_APP}`, data);

    return response.data;
  }
}
