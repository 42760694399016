import Axios from 'axios';
import * as axios from 'axios/index';
import { STORAGE_TOKEN_COGNITO } from '../../../util/Constant';
import { Routes } from '../../../routes';

export const Api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const ApiBusiness = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BUSINESS,
});

export function getServerUrl() {
  if (window.baseHref) {
    return window.baseHref;
  }
  return '';
}

const signAuth = async () => {
  // await localStorage.clear( );
  // window.location.replace(Routes.LOGIN)
};

export function doGet(url, token, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token || idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // Critico
      signAuth();
    }
  });
}

export function doGetPromotic(url  , token, params = {} ) {
    const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
    const Segment = 20000008;
    return axios({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token || idTokenCognito}`,
        segment: Segment,
      },
      data: params,
    }).catch((error) => {
      if (error.response === undefined) {
        // Critico
        signAuth();
      }
    });
}

export function doGetWithParams(url, headerParams = null, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `${idTokenCognito}`,
  };

  if (headerParams) {
    headers = { ...headers, ...headerParams };
  }
  return axios({
    url,
    method: 'get',
    headers,
    data: params,
  }).catch((error) => {
    console.log('error', error);
  });
}

export function doGetWithEntity(url, entity, token, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token || idTokenCognito}`,
      entity,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // Critico
      signAuth();
    }
  });
}
export function doGetUnauthorized(url, params = {}) {
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  }).catch((error) => {
    console.log('error', error);
  });
}

export function doPostUnauthorized(url, params = {}) {
  // const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  });
}

export function doGetImagesorFiles(url) {
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((error) => {
    console.log('error', error);
  });
}

export function doPost(url, params = {}, token) {
  const idTokenCognito = token || localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    console.log('error', error);
    if (error.response === undefined) {
      // Critico
      signAuth();
    }
  });
}

export function doPostValidateOtp(url, params = {}, token, type, otp, headerParams = null) {
  let headers = {
    'Content-Type': 'application/json',
    'validation-type': type,
    Authorization: `${token}`,
  };

  if (headerParams) {
    headers = { ...headers, ...headerParams };
  }

  if (otp) {
    headers = { ...headers, 'validation-data': otp };
  }

  return axios({
    url,
    method: 'post',
    headers,
    data: params,
  }).catch((error) => {
    console.log('error', error);
    if (error.response === undefined) {
      // Critico
      signAuth();
    }
  });
}

export function doPostOtp(url, params = {}, token, type) {
  return axios({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'validation-type': type,
      Authorization: `${token}`,
    },
    data: params,
  }).catch((error) => {
    console.log('errpr', error);
    if (error.response === undefined) {
      // Critico
      signAuth();
    }
  });
}

export function doPostWithParams(url, params = {}, headerParams = null) {
  const token = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `${token}`,
  };

  if (headerParams) {
    headers = { ...headers, ...headerParams };
  }

  return axios({
    url,
    method: 'post',
    headers,
    data: params,
  }).catch((error) => {});
}

export function doPostFormFile(url, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    console.log('error', error);
  });
}

export function doDelete(url, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${idTokenCognito}`,
    },
    data: params,
  });
}
