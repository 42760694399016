import moment from 'moment';
import 'moment/locale/es';

import {
  Icons,
  Background,
  MAGAZINE_BOLETIN_VIVE_SALUD,
  MAGAZINE_BOLETIN_FINANCIERO,
  MAGAZINE_BOLETIN_QUEDATE_CASA,
  routesSletter,
  COUPON_VIGENCY,
  typeReceipt,
} from './Constant';
import { OptionsProduct } from '../app/shared/constants/OptionsProduct';
import { menuCode } from '../app/shared/constants/Strings';
import { ExpiredCoupon } from '../app/shared/model/ExpiredCoupon';

export function getIcon(type) {
  if (Icons[type] !== undefined) {
    return Icons[type].url;
  }
  return Icons.default.url;
}

export function getBackground(type) {
  if (Background[type] !== undefined) {
    return Background[type].url;
  }
  return Background.default.url;
}

export function removeDuplicates(originalArray, prop) {
  const newArray = [];
  const lookupObject = {};

  for (const i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (const i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export function removeElement(value, array) {
  return array.filter((ar) => {
    return ar.branchId !== value;
  });
}

export function changeRoute(codeRoute) {
  switch (codeRoute) {
    case MAGAZINE_BOLETIN_VIVE_SALUD:
      return routesSletter.third;
    case MAGAZINE_BOLETIN_FINANCIERO:
      return routesSletter.fourth;
    case MAGAZINE_BOLETIN_QUEDATE_CASA:
      return routesSletter.second;
    default:
      return routesSletter.third;
  }
}

export function existInUrl(value) {
  return window.location.href.indexOf(value) !== -1;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDocumentInfo(clientDocument) {
    const documentLetter = clientDocument.charAt(0); 
    const documentNumber = clientDocument.substr(1); 
    const document = {
        documentLetter,
        documentNumber
    }
    return document || {};
}
export function capitalizeSentence(s) {
  return s.toLowerCase().replace(/\b./g, (a) => a.toUpperCase());
}
export function animate(element, className) {
  element.classList.add(className);
  setTimeout(() => {
    element.classList.remove(className);
    setTimeout(() => {
      animate(element, className);
    }, 500);
  }, 1000);
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function isEmptyValue(value) {
  return value === '';
}
export function clearValue(value) {
  return ['', undefined, null].indexOf(value) === -1 ? value.trim() : null;
}
export function typeErrorAction (error, closeModal, closeModalError , errorMesagge){
    switch (error) {
        case 'COUPON':
            return closeModal();
        case 'ID_PASARELA':
            return closeModalError();
        case 'ID_MONEDA':
            return closeModalError();
        case 'URL' :
            return closeModal();
        default:
            return errorMesagge ? closeModal() : closeModalError();
    }    
}
export function maxLengthCheck(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function chunkArray(myArray, chunkSize) {
  let index;
  const newArray = [];
  const arrayLength = myArray.length;
  const tempArray = [];
  for (const item of myArray) {
    newArray.push(item);
  }
  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = newArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
}

export function formatDaysWeek(days, myArray) {
  for (const item of myArray) {
    days.map((w, index) => (item[index].children[0].innerHTML = w));
  }
}

export function validateOnlyNumbers(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  return regex.test(key);
}
export function validateOnlyText(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[a-zA-Z ]/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function formatCodeInput(evt) {
  const theEvent = evt || window.event;
  const validate = validateOnlyNumbers(evt);
  if (validate) {
    const index = theEvent.target.value.lastIndexOf('-');
    const test = theEvent.target.value.substr(index + 1);
    if (test.length === 1 && index !== 5) {
      theEvent.target.value += '-';
    }
    return theEvent;
  }
  theEvent.preventDefault();
  return false;
}

export function removeDashInput(value) {
  const letter = /-/g;
  return value.replace(letter, '');
}

export function getDateToday() {
  const d = new Date();

  const dateFormatted = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
    .getDate()
    .toString()
    .padStart(2, '0')}`;
  return dateFormatted;
}

export function getLastThreeYear() {
  const listYear = [];
  const year = getCurrentYear();
  listYear.push(
    { value: year, label: year },
    { value: year - 1, label: year - 1 },
    { value: year - 2, label: year - 2 }
  );
  return listYear;
}

export function getLastTwoYear() {
  const listYear = [];
  const year = getCurrentYear();
  listYear.push({ value: year, label: year }, { value: year - 1, label: year - 1 });
  return listYear;
}

export function getCurrentYear() {
  const date = new Date();
  return date.getFullYear();
}

export function formatPhone(numberPhone) {
  if (numberPhone) {
    let fixNumber = numberPhone;
    if (numberPhone.indexOf('+51') !== -1) {
      fixNumber = numberPhone.replace('+51', '');
    }
    fixNumber = parseInt(fixNumber);
    return fixNumber.toLocaleString('es-PE', {}).replace(/,/g, ' ');
  }
  return '';
}

export function getProperty(value) {
  const infoPage = {};

  switch (value) {
    case 'rentaTotal': {
      infoPage.title = 'Renta Total';
      infoPage.property = 'RENTA_TOTAL';
      return infoPage;
    }

    case 'rrvv': {
      infoPage.title = 'Renta Vitalicias';
      infoPage.property = 'RENTA_VITALICIA';
      return infoPage;
    }

    case 'ahorroTotal': {
      infoPage.title = ' Ahorro Total';
      infoPage.property = 'AHORRO_TOTAL';
      return infoPage;
    }
    default:
      return infoPage;
  }
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function fixStructBase64(base64Data) {
  let base64Clear = '';
  const base64Split = base64Data.split(',');
  if (base64Split.length > 0) {
    base64Clear = base64Split[1];
    const lastCharacter = '"';
    if (base64Split[1].substring(base64Split[1].length - 1) === lastCharacter) {
      base64Clear = base64Split[1].substring(0, base64Split[1].length - 1);
    }
  }
  return base64Clear;
}

export function getOptionsToProduct(options) {
  return OptionsProduct.filter((option) => {
    return options.includes(option.code);
  });
}

export function orderArray(items, campoOrder) {
  return items.sort((a, b) => {
    if (a[campoOrder] > b[campoOrder]) return 1;
    if (a[campoOrder] < b[campoOrder]) return -1;
    return 0;
  });
}

export function reorderMonths(months) {
  const monthsMobile = [];
  const monthsToday = new Date().getMonth() - 1;
  months.forEach((m, index) => {
    if (index > monthsToday) {
      monthsMobile.push(m);
    }
  });
  months.forEach((m, index) => {
    if (index <= monthsToday) {
      monthsMobile.push(m);
    }
  });
  return monthsMobile;
}

export const formatClinics = (array) =>
  array.reduce((cache, current) => {
    const department = current['department'];
    const district = current['district'];
    const voidCache = isEmpty(cache);
    if (!voidCache) {
      const itemDepartment = cache.find((e) => e.value === department);
      const indexDeparment = cache.findIndex((e) => e.value === department);

      if (itemDepartment) {
        const indexDistrict = itemDepartment?.districts?.findIndex((e) => e.value === district);

        if (indexDistrict !== -1) {
          const acumulateClinics = itemDepartment?.districts[indexDistrict].clinicas;
          acumulateClinics.push(current);
          cache[indexDeparment].districts[indexDistrict].clinicas = acumulateClinics;
          return [...cache];
        }

        const currentDistrict = [];
        currentDistrict.value = district;
        currentDistrict.label = district;
        currentDistrict.clinicas = [current];
        const acumulateDistricts = itemDepartment?.districts;
        acumulateDistricts.push(currentDistrict);
        cache[indexDeparment].districts = acumulateDistricts;
        return [...cache];
      }
    }
    const obj = [];
    obj.value = department;
    obj.label = department;
    obj.name = department;
    const currentDistrict = [];
    currentDistrict.value = district;
    currentDistrict.label = district;
    currentDistrict.clinicas = [current];
    obj.districts = [currentDistrict];

    return voidCache ? [obj] : [...cache, obj];
  }, {});

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function scrollToTop() {
  window.scrollTo({
    top: 300,
    behavior: 'smooth',
  });
}

export function sortMagazineByDate(arr) {
  arr.sort((a, b) => {
    return b.dateRegister - a.dateRegister;
  });
  return arr;
}

const support = (function () {
  if (!window.DOMParser) return false;
  const parser = new DOMParser();
  try {
    parser.parseFromString('x', 'text/html');
  } catch (err) {
    return false;
  }
  return true;
})();

export function stringToHTML(str) {
  // If DOMParser is supported, use it
  if (support) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  }

  // Otherwise, fallback to old-school method
  const dom = document.createElement('div');
  dom.innerHTML = str;
  return dom;
}

export function sortArrayByDates(arr, field = 'date') {
  const arrayForSort = [...arr];
  arrayForSort.sort((a, b) => {
    return new Date(b[field]) - new Date(a[field]);
  });
  return arrayForSort;
}

export function optionsToProduction(options) {
  const optionsAvailable = ['DCD', 'BLT', 'DCP', 'CP'];
  if (isDevelopment) {
    return options;
  }
  return options.filter((option) => optionsAvailable.includes(option.code));
}

export function mapToSelect(options, propertyId, propertyLabel) {
  const optionsReturn = options.map((option) => {
    return { ...option, value: option[propertyId], label: option[propertyLabel] };
  });
  optionsReturn.unshift({ value: '', label: 'Seleccione' });
  return optionsReturn;
}

export function dateCurrentYear(unixTime) {
  const currentYear = new Date().getFullYear();
  const time = transformUnixToDate(unixTime * 1000).slice(-4);
  return currentYear == time;
}

export function getProductInfo( servicesDetail = [] , productCode = '') {
    let productData = servicesDetail.find((e) => e.product === productCode);
    return productData || {};
}


export function getTypeAdvert(products, listAdvert, issuesDateSoat = []) {
  const hasSoatPromotions = listAdvert.some((p) => p.products.includes(menuCode.SOAT));
  let bannersFilter = listAdvert;
  const advertisements = [];
  const productsID = ['10000000'];
  if (hasSoatPromotions) {
    bannersFilter = listAdvert.filter((p) => {
      if (p.products.includes(menuCode.SOAT) && p.discountId) {
        return issuesDateSoat.some((issuesDate) =>
          dateInVigency(issuesDate, p.startDate, p.endDate)
        );
      }
      return true;
    });
  }
  products.forEach((item) => {
    if (item) {
      productsID.push(item.product);
    }
  });

  if (bannersFilter.length > 0) {
    bannersFilter
      .filter((adv) => adv.products.some((prodAdv) => productsID.includes(prodAdv)))
      .map((item) => {
        const advertisement = {
          img: item.image.urlWeb,
          imgResponse: item.image.urlMobile,
          url: item.link,
          products: item.products,
          name: item.name,
          discountId: item.discountId,
        };
        advertisements.push(advertisement);
      });
  }

  return advertisements;
}

export const hasPropertyTrue = (dataArray, propertyName) => 
 dataArray.some((item) => item.hasOwnProperty(propertyName) && item[propertyName] === true);

export function showMenuCoupon(products, listAdvert, issuesDateSoat = [], showPromoFree) {
  const existSoat = products.some((item) => item.product === menuCode.SOAT);
  if (!existSoat) return false;

  const advertList = getTypeAdvert(products, listAdvert, issuesDateSoat);
  const promoSoatVigency = advertList.some(
    (b) => b.products.includes(menuCode.SOAT) && b.discountId
  );
  return promoSoatVigency || showPromoFree;
}

export function isEmptyArray(arr) {
  return arr.length === 0;
}

export function getFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getMes(numberMonth) {
  let month = '';
  switch (numberMonth) {
    case '01':
      month = 'Enero';
      break;
    case '02':
      month = 'Febrero';
      break;
    case '03':
      month = 'Marzo';
      break;
    case '04':
      month = 'Abril';
      break;
    case '05':
      month = 'Mayo';
      break;
    case '06':
      month = 'Junio';
      break;
    case '07':
      month = 'Julio';
      break;
    case '08':
      month = 'Agosto';
      break;
    case '09':
      month = 'Setiembre';
      break;
    case '10':
      month = 'Octubre';
      break;
    case '11':
      month = 'Noviembre';
      break;
    case '12':
      month = 'Diciembre';
      break;
    default:
      month = '-';
  }
  return month;
}

export function findValueByField(form = [], field, propertyReturn) {
  let value = '';
  const find = form.find((item) => {
    return item.field === field;
  });
  if (find) {
    value = propertyReturn ? find[propertyReturn] : find.value;
  }
  return value;
}

export function findValue(array = [], valueSearch, property = 'value') {
  let value = '';

  const find = array.find((item) => item[property] === valueSearch);

  if (find) {
    value = find.label;
  }
  return value;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function transformDatetoUnix(date) {
  if (date) {
    return moment(date).format('X');
  }
  return null;
}
export function getBannerAvailable(startDate, endDate, discountId = undefined) {
  let isAvailable = false;
  const today = new Date().getTime() / 1000;
  if (startDate !== null && endDate !== null) {
    const startDateUnix = parseInt(transformDatetoUnix(startDate));
    const endDateUnix = parseInt(transformDatetoUnix(endDate)) + 86399;
    if (discountId && startDateUnix <= today) {
      isAvailable = true;
    } else if (startDateUnix <= today && endDateUnix >= today) {
      isAvailable = true;
    }
  }

  return isAvailable;
}

export function getMaxLenghCuentasByType(typeBack, typeAccount) {
  let maxLength;
  let maxLengthByAccount;
  switch (typeBack) {
    case 'BCP':
      maxLength = {
        CA: 14,
        CC: 13,
        CM: 14,
      };
      break;
    case 'Interbank':
      maxLength = {
        CA: 13,
        CC: 13,
        CM: 13,
      };
      break;
    case 'Scotiabank':
      maxLength = {
        CA: 10,
        CC: 10,
        CM: 10,
      };
      break;
    case 'BBVA':
      maxLength = {
        CA: 18,
        CC: 18,
        CM: 18,
      };
      break;
    case 'Banco de Comercio':
      maxLength = {
        CA: 12,
        CC: 12,
        CM: 12,
      };
      break;
    case 'Banco de la Nación':
      maxLength = {
        CA: 11,
        CC: 11,
        CM: 11,
      };
      break;
    case 'Banco Falabella':
      maxLength = {
        CA: 12,
        CC: 12,
        CM: 12,
      };
      break;
    case 'Banco GNB Perú':
      maxLength = {
        CA: 10,
        CC: 10,
        CM: 10,
      };
      break;
    case 'Banco Interamericano de Finanzas':
      maxLength = {
        CA: 10,
        CC: 10,
        CM: 10,
      };
      break;
    case 'Banco Ripley':
      maxLength = {
        // 10 y 11
        CA: 10,
        CC: 10,
        CM: 10,
      };
      break;
    case 'Banco Santander Perú':
      maxLength = {
        CA: 11,
        CC: 11,
        CM: 11,
      };
      break;
    case 'Banco Pichincha':
      maxLength = {
        CA: 12,
        CC: 12,
        CM: 12,
      };
      break;
    default:
      maxLength = {
        CA: 13,
        CC: 13,
        CM: 13,
      };
      break;
  }
  switch (typeAccount) {
    case 'Ahorros':
      maxLengthByAccount = maxLength.CA;
      break;
    case 'Cta Corriente':
      maxLengthByAccount = maxLength.CC;
      break;
    case 'Maestra':
      maxLengthByAccount = maxLength.CM;
      break;
    default:
      maxLengthByAccount = maxLength.CA;
      break;
  }

  return maxLengthByAccount;
}

export function getMaxLengthDocument(typeBack) {
  let maxLength = 8;
  switch (typeBack) {
    case 'D.N.I':
      maxLength = 8;
      break;
    case 'R.C.I':
      maxLength = 11;
      break;
    case 'C.E':
      maxLength = 11;
      break;
    default:
      maxLength = 8;
      break;
  }
  return maxLength;
}

export function getValueDocument(typeDocument) {
  let value = 'DNI';
  switch (typeDocument) {
    case 'L':
      value = 'DNI';
      break;
    case 'R':
      value = 'RUC';
      break;
    case 'E':
      value = 'CE';
      break;
    case 'G':
      value = 'PPT';
      break;
    default:
      value = 'DNI';
      break;
  }
  return value;
}
export function getValueDocumentName(typeDocument) {
  let value = 'DNI';
  switch (typeDocument) {
    case 'L':
      value = 'DNI';
      break;
    case 'R':
      value = 'RUC';
      break;
    case 'E':
      value = 'Carnet de Extranjería';
      break;
    case 'G':
      value = 'Pasaporte';
      break;
    default:
      value = 'DNI';
      break;
  }
  return value;
}

export function getValueDocumentWord(typeDocument) {
  let value = 'L';
  switch (typeDocument) {
    case 'DNI':
      value = 'L';
      break;
    case 'PPT':
      value = 'G';
      break;
    case 'RUC':
      value = 'R';
      break;
    case 'CE':
      value = 'E';
      break;
    default:
      value = 'L';
      break;
  }
  return value;
}

export function validateAllFields(form = [], property, excludeIndex = -1) {
  let isValid = true;
  form.forEach((item, index) => {
    if (index !== excludeIndex) {
      const value = property ? item[property] : item;
      if (!value) {
        isValid = false;
      }
    }
  });

  return isValid;
}

export const validateFieldForm = (form, excludeField) => {
  let isValid = true;
  form.forEach((item) => {
    if (item.required && item.field !== excludeField) {
      if (!item.value) {
        item.error = 'Campo requerido';
        isValid = false;
      } else {
        item.error = '';
      }
      if (item.maxLength && item.value) {
        if (item.value.length !== item.maxLength) {
          item.error = `Ingrese ${item.maxLength} digitos`;
          isValid = false;
        } else {
          item.error = '';
        }
      }
      if (item.maxValue && item.value) {
        if (item.value > item.maxValue) {
          item.error = `Ingrese un valor menor a ${item.maxValue}`;
          isValid = false;
        } else {
          item.error = '';
        }
      }
    }
  });

  return [isValid, form];
};

export function getCodeForm(typeForm) {
  let value = 'MODP';
  switch (typeForm) {
    case 'CMP':
      value = 'MODP';
      break;
    case 'CPC':
      value = 'PCAS';
      break;
    case 'ADB':
      value = 'ADBN';
      break;
    case 'CGS':
      value = 'MBGS';
      break;
    default:
      value = 'MODP';
      break;
  }
  return value;
}

export function getDescriptionToform(form, code) {
  let description = '';

  if (code) {
    switch (code) {
      case 'CMP':
        description = `Deseo actualizar el banco, tipo de cuenta o moneda de la cuenta donde recibo mis pagos:
           Forma de Pago: ${findValueByField(form[code], 'formaDePago')}
           Banco: ${findValueByField(form[code], 'banco')}
           Tipo de Cuenta: ${findValueByField(form[code], 'tipoDeCuenta')}
           Moneda: ${findValueByField(form[code], 'moneda')}
           Número de cuenta: ${findValueByField(form[code], 'account')}
           CCI: ${findValueByField(form[code], 'cci')}
           \nSolicitud desde Net Privada`;
        break;

      case 'CPC': // Cambio de % de capital asegurado
        description = `Deseo cambiar el % de capital asegurado de mis beneficiarios:
           Beneficiario 1:${findValueByField(form[code], 'tipo-num-documento-1')}
           Nuevo % de capital asegurado:${findValueByField(form[code], 'capitalAsegurado')}
           `;
        const addFieldCPC = findValueByField(form[code], 'addField', 'fieldAdd');
        if (addFieldCPC > 0) {
          let additionalDescription = '';
          for (let index = 0; index <= addFieldCPC - 1; index++) {
            additionalDescription += `Beneficiario ${index + 2}: ${findValueByField(
              form[code],
              `tipo-num-documento${index + 1}`
            )}
            Nuevo % de capital asegurado:${findValueByField(
              form[code],
              `capitalAsegurado${index + 1}`
            )}`;
          }
          description += additionalDescription;
        }
        description += '\nSolicitud desde Net Privada';
        break;

      case 'CGS':
        description = `Exclusión: Deseo modificar al beneficiario original de la cobertura de Gasto de Sepelio por el indicado a continuación:
           Nombres y Apellidos: ${findValueByField(form[code], 'cobrante')}
           Documento: ${findValueByField(form[code], 'tipoDocument')}-${findValueByField(
          form[code],
          'nroDocument'
        )}
           Fecha Nacimiento: ${findValueByField(form[code], 'date')}
           Vínculo: ${findValueByField(form[code], 'vinculo')}
            `;
        const addFieldCGS = findValueByField(form[code], 'addField', 'fieldAdd');
        if (addFieldCGS > 0) {
          let additionalDescription = '';
          for (let index = 0; index <= addFieldCGS - 1; index++) {
            additionalDescription += `
           Nombres y Apellidos: ${findValueByField(form[code], `cobrante${index + 1}`)}
           Documento: ${findValueByField(
             form[code],
             `tipoDocument${index + 1}`
           )}-${findValueByField(form[code], `nroDocument${index + 1}`)}
           Fecha Nacimiento: ${findValueByField(form[code], `date${index + 1}`)}
           Vínculo: ${findValueByField(form[code], `vinculo${index + 1}`)}
            `;
          }
          description += additionalDescription;
        }
        description += '\nSolicitud desde Net Privada';
        break;
    }
  }

  return description;
}

export function transformUnixToDate(unixTime) {
  if (unixTime) {
    const date = new Date(unixTime);
    return date.toLocaleDateString('es-PE');
  }
  return null;
}
export function transformUnixToDateGMT(unixTime) {
  if (unixTime) {
    return moment(unixTime).utc().format('DD/MM/YYYY');
  }
  return null;
}

export function getDate(formattedDateTime) {
  let dateFormat = '--';
  if (formattedDateTime) {
    dateFormat = moment(formattedDateTime, 'DD/MM/YYYY hh:mm:ss A').format('DD/MM/YYYY');
  }

  return dateFormat;
}

export function urlLinkCoupon(coupon , typeCoupon) {
  const Values = {
    2: coupon.pdfUrl,
    4: coupon.smartLink,
    5: coupon.smartLink,
  };
  return Values[typeCoupon];
}

export function getMonthYear(formattedDateTime) {
    let monthYearFormat = '--';
    if (formattedDateTime) {
      monthYearFormat = moment(formattedDateTime, 'DD/MM/YYYY hh:mm:ss A').format('MMMM, YYYY');
      monthYearFormat = monthYearFormat.charAt(0).toUpperCase() + monthYearFormat.slice(1);
    }
  
    return monthYearFormat;
  }

export function getState(number_state) {
    if(number_state === 0)  return COUPON_VIGENCY.AVAILABLE;
    return COUPON_VIGENCY.PROCESS;
 }
 
export function sortCards(cardResponse, idMoney) {
    const cardPendients = cardResponse.filter((c) => c.reciboPendiente === typeReceipt.PENDIENTS);
    const cards = cardPendients.map((c) => {
      c.effecDate = getMonthYear(c.effecDate);
      c.limitDate = getDate(c.limitDate);
      c.premiun = formatCurrency(c.premiun, idMoney);
      c.cuponVigente = getState(c.cuponVigente);
      return new ExpiredCoupon(c);
    });
  
    return cards;
  }
  export function getAddReceipts(cards, receipts) {
    let initialValue = 0;
    const sumaPrima = cards.reduce((totalSum, card) => {
      if (card?.nreceipt && card?.premiun && receipts.includes(card.nreceipt)) {
        const premiunValue = parseFloat(card?.premiun.replace(/[^\d.]/g, ""));
        totalSum = totalSum + premiunValue;
      }
      return totalSum;
    }, initialValue );
  
    return sumaPrima;
 }
 export function formatCurrency(amount, idMoney) {
   const valueMoney = idMoney === "1" ? 'S/' : '$ ';
    return `${valueMoney} ${parseFloat(amount).toFixed(2)}`;
  }

export function dateNowFormatted() {
  const dateNow = moment().tz('America/Lima', true).locale('es').format('DD - MMMM - YYYY');
  let dateFormatted = dateNow.replace('-', 'de');
  dateFormatted = dateFormatted.replace('-', 'de');
  return dateFormatted;
}
export function dateInVigency(dateValidate, initDate, endDate) {
  const validateArray = dateValidate.split('-');
  const initArray = initDate.split('-');
  const endArray = endDate.split('-');

  const init = new Date(initArray[0], parseInt(initArray[1], 10) - 1, initArray[2]);
  const end = new Date(endArray[0], parseInt(endArray[1], 10) - 1, endArray[2], 23, 59, 59);
  const validate = new Date(validateArray[0], parseInt(validateArray[1], 10) - 1, validateArray[2]);
  return validate >= init && validate <= end;
}

const breakPointsCarousel = [
  { width: 1, itemsToShow: 1 },
  { width: 650, itemsToShow: 2 },
  { width: 980, itemsToShow: 3 },
  { width: 1250, itemsToShow: 4 },
];

export const carouselProps = {
  breakPoints: breakPointsCarousel,
  enableAutoPlay: true,
  autoPlaySpeed: 4000,
  className: 'carousel',
  disableArrowsOnEnd: false,
};

export function dateLastMonth() {
  const current = new Date();
  const lastMonth = current.setMonth(current.getMonth() - 3);
  return new Date(lastMonth).toLocaleDateString();
}
export function generateArrayIterable(count) {
  return Array.from({ length: count }, (_, i) => i + 1);
}

export const transformArray = (array, customName) => array.map((item) => ({ [customName]: item }));

export function validationSequencialPass(password) {
  let validation = true;

  password.split('').every((i, index) => {
    const s1 = password.charCodeAt(index);
    const s2 = password.charCodeAt(index + 1);
    const s3 = password.charCodeAt(index + 2);
    const s4 = password.charCodeAt(index + 3);
    if (Math.abs(s1 - s2) === 1 && s1 - s2 === s2 - s3 && s2 - s3 === s3 - s4) {
      validation = false;
    }
    return validation;
  });
  return validation;
}

export function validateDocumentPass(password, documentNumber) {
  return !password.includes(documentNumber);
}

export const getFadError = (dni) => {
    //TODO: Change for api in the future
    const errors = JSON.parse(sessionStorage.getItem('fadError')) || {};
    return errors[dni] || 0;
  };

export function isLocalHost() {
  const LOCALHOST = 'localhost';
  const { port, hostname } = window.location;
  if (hostname === LOCALHOST) return `http://${hostname}:${port}`;

  return false;
}
