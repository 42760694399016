import ClientsService from '../../../shared/services/ClientsService';
import { initialState } from '../state';
import { ACCESS_TOKEN_COGNITO, configAppAuth } from '../../../../util/Constant';
import { Routes } from '../../../../routes';
import { setAuthLoading } from './auth-loading';

export const CLEAN_AUTH = 'Auth/CLEAN_AUTH';

export const cleanAuth = () => ({
  type: CLEAN_AUTH,
});

export const logoutUser = (history) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      // TODO: STORAGE_TOKEN_COGNITO should be remove, but the api only works with ACCESS_TOKEN_COGNITO
      const token = localStorage.getItem(ACCESS_TOKEN_COGNITO);
      const data = {
        authentication: {
          ...configAppAuth,
          userData: {
            token,
          },
        },
      };
      await ClientsService.logoutClient(data);
      dispatch(cleanAuth());
      localStorage.clear();
      history.push(Routes.LOGIN);
    } finally {
      dispatch(setAuthLoading(false));
    }
  };
};

export const reducer = () => initialState;
