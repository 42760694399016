const EVENT_MODULE = {
    PROCESS_INIT: 'PROCESS_INIT',
    PROCESS_ERROR: 'PROCESS_ERROR',
    PROCESS_COMPLETED: 'PROCESS_COMPLETED'
}

const MINIMUN_REQUIREMENTS = {
    CAMARA: 5,
    PROCESADOR: 2,
    IOS: 11,
    RAM: 2,
    ANDROID: 5
}

const ERROR_CODE = {
    NO_DATA: -1,
    AUTH_FAD: -2,
    GET_KEY: -3,
    PROOF_LIFENESS: -4,
    AUTH_PROTECTA: -5,
    PROTECTA_DATA: -6,
    COMPARE_FACES: -7,
    SAVE_VALIDATION: -8,
    INTERNAL_ERROR: -9,
    LOCATION_PERMISSION: -10,
    CAMERA_PERMISSION: -11,
    FULLSCREEN_PERMISSION: -12
}
const ERROR_MESSAGE = {
    [ERROR_CODE?.NO_DATA]: 'E01: Datos no encontrados.',
    [ERROR_CODE?.AUTH_FAD]: 'E02: Autenticación fallida.',
    [ERROR_CODE?.GET_KEY]: 'E03: Problema con la clave.',
    [ERROR_CODE?.PROOF_LIFENESS]: 'E04: Verificación fallida.',
    [ERROR_CODE?.AUTH_PROTECTA]: 'E05: Autenticación no exitosa.',
    [ERROR_CODE?.PROTECTA_DATA]: 'E06: Problema con los datos.',
    [ERROR_CODE?.COMPARE_FACES]: 'E07: Comparación fallida.',
    [ERROR_CODE?.SAVE_VALIDATION]: 'E08: No se pudo guardar.',
    [ERROR_CODE?.INTERNAL_ERROR]: 'E09: Error interno.',
    [ERROR_CODE?.LOCATION_PERMISSION]: 'E10: Ubicación necesaria.',
    [ERROR_CODE?.CAMERA_PERMISSION]: 'E11: Cámara necesaria.',
    [ERROR_CODE?.FULLSCREEN_PERMISSION]: 'E12: Pantalla completa necesaria.'
};
const MESSAGE_ERROR = {
    REQUIREMENTS: {
        ICON: 'cameraFAD',
        TITLE: 'Cámara no disponible',
        DESCRIPTION: "Por favor intenta nuevamente desde un equipo con cámara de video frontal." +
            " Recuerda que también puedes realizar tus requerimientos llamando al 01-391-3000, " +
            "de lunes a viernes de 9:00 a.m. a 6:00 p.m. excepto feriados ¡Gracias por tu tiempo!",

    },
    BLOCKED: {
        ICON: 'alertFAD',
        TITLE: 'Registro facial bloqueado',
        DESCRIPTION: `
            Has superado el límite de intentos.
            Recuerda que también puedes realizar tus requerimientos llamando al 01-391-3000,
            de lunes a viernes de 9:00 a.m. a 6:00 p.m. excepto feriados.
            Podrás realizar un nuevo intento luego de 24 horas ¡Te esperamos!
       
    `
    }

};

export { EVENT_MODULE, ERROR_CODE, ERROR_MESSAGE, MINIMUN_REQUIREMENTS, MESSAGE_ERROR }