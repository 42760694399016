import isEmpty from 'loadsh/isEmpty';

import { setAuthLoading } from './auth-loading';
import { menuCode, services } from '../../../shared/constants/Strings';
import LoadProductosServices from '../../../shared/services/LoadProductosServices';
import { removeDuplicates, validateHasVDP } from '../../../../util/Util';
import {
  ACCESS_TOKEN_COGNITO,
  FIELD_NEXTTIME_LOGIN,
  STORAGE_IS_BUSINESS,
  STORAGE_TOKEN_COGNITO,
  configAppAuth,
} from '../../../../util/Constant';
import ClientsService from '../../../shared/services/ClientsService';
import { createBanners } from './set-banners';
import { Policy } from '../../../shared/model/Policy';
import { createErrorPage } from './set-error-page';

export const USER_LOGIN = 'Auth/USER_LOGIN';
export const CORE_INFO = 'Auth/CORE_LOGIN';

export const setLoginUser = (user) => ({
  type: USER_LOGIN,
  payload: user,
});

export const setLoginCore = (user) => ({
  type: CORE_INFO,
  payload: user,
});

export const validateLoginUser = (documentId, password, documentType, policyVigent, key) => {
  return async (dispatch) => {
    const userInfo = {};
    const params = {
      authentication: {
        ...configAppAuth,
        userData: {
          user: `${documentType}${documentId}`,
          password,
        },
      },
    };

    try {
      dispatch(setAuthLoading(true));
      const { authenticationResponse, errorAlias, message } =
        await ClientsService.responseAuthClient(params);

      if (authenticationResponse && authenticationResponse?.authenticationState === 200) {
        const { securityToken, accessToken, refreshToken } =
          authenticationResponse.authenticationData;
        userInfo.isBusiness = documentType === 'R';
        userInfo.valid = true;
        userInfo.document = documentId;
        userInfo.documentType = documentType;

        localStorage.setItem(ACCESS_TOKEN_COGNITO, accessToken);
        localStorage.setItem(STORAGE_TOKEN_COGNITO, securityToken);
        localStorage.setItem(FIELD_NEXTTIME_LOGIN, new Date());
        localStorage.setItem(STORAGE_IS_BUSINESS, userInfo.isBusiness);

        userInfo.attributes = await setClient();
        userInfo.showPromoFree = await setClientDiscounts();
        userInfo.issuesDateSoat = await setIssuesDateProductsPolicies();
        userInfo.productsDetail = await setProductsPolicies();
        userInfo.kushkiAtributes = await setAtributeKushki(
            policyVigent,
            key,
            userInfo.productsDetail,
            dispatch
          );
        await dispatch(createBanners());
      } else {
        userInfo.valid = false;
        userInfo.codeError = errorAlias;
        userInfo.message = message;
      }
      dispatch(setLoginUser(userInfo));
      dispatch(setAuthLoading(false));
    } catch ({ code, message }) {
      userInfo.valid = false;
      userInfo.codeError = code;
      userInfo.message = message;
      dispatch(setLoginUser(userInfo));
      dispatch(setAuthLoading(false));
    }
  };
};

const matchAttributes = (data) => {
  return {
    middleName: data?.contact?.lastName,
    familyName: data?.contact?.lastName2,
    givenName: data?.contact?.name,
    legalName: data?.contact?.legalName,
    email: data?.contactEmail.email,
    phoneNumber: data?.contactPhone?.phone,
    firstLogin: data?.isFirstLogin,
    pdpNetPrivada: data?.isAdvertisingTermsAccepted,

    document: data?.contactDocument?.document,
    isAcceptDataTeatmentPolicyAccepted: data?.isAcceptDataTeatmentPolicyAccepted || true,
  };
};

const setClientDiscounts = async () => {
  try {
    const {
      data: { assignedDiscounts },
    } = await ClientsService.responseGetClient();
    return assignedDiscounts.some((d) => d.discountId === 'autowash');
  } catch (error) {}
  return false;
};
const setClient = async () => {
    const { data = [] } = await ClientsService.responseGetClient();
    return matchAttributes(data); 
};
const setProductsPolicies = async () => {
  try {
    const { data, status } = await LoadProductosServices.responseProductosPolicies();
    if (status?.id === 200) {
      return data
        ?.filter((e) => services[e.product])
        .map((e) => {
          const { productDescription } = e;
          const policies = e?.policies.map((p) => new Policy({ ...p, productDescription })) || [];
          return { ...e, policies };
        });
    }
  } catch (error) {}
  return [];
};
const setIssuesDateProductsPolicies = async () => {
  try {
    const { data, status } = await LoadProductosServices.responseProductosPolicies();
    if (status?.id === 200) {
      return setProductPolicies(data);
    }
  } catch (error) {}
  return [];
};

export const setAttributesCore = () => {
  return async (dispatch) => {
    const attributesCore = {};
    try {
      const responseClientCore = await ClientsService.responseGetClientPersonal();
      attributesCore.phone = responseClientCore.data.lastMobilePhone;
      attributesCore.email = responseClientCore.data.lastEmail;
      attributesCore.indPDP = responseClientCore.data.indPDP;
      attributesCore.lastName = responseClientCore.data.lastName;
      attributesCore.lastName2 = responseClientCore.data.lastName2;
      attributesCore.legalName = responseClientCore.data.legalName;
      attributesCore.names = responseClientCore.data.names;
      attributesCore.nnationality = responseClientCore.data.nnationality;
      attributesCore.ntitle = responseClientCore.data.ntitle;
      attributesCore.sexClient = responseClientCore.data.sexClient;
      attributesCore.speciality = responseClientCore.data.speciality;
      attributesCore.civilState = responseClientCore.data.civilState;
      attributesCore.sblockade = responseClientCore.data.sblockade;
      attributesCore.sblocklaft = responseClientCore.data.sblocklaft;
      attributesCore.indSisClient = responseClientCore.data.indSisClient;
      attributesCore.indPolizaElec = responseClientCore.data.indPolizaElec;
      attributesCore.indProtegData = responseClientCore.data.indProtegData;
      attributesCore.indCuspp = responseClientCore.data.indCuspp;
      attributesCore.belcorpType = responseClientCore.data.belcorp
        ? responseClientCore.data.belcorp.type
        : null;

      dispatch(setLoginCore(attributesCore));
    } catch (error) {
      dispatch(setLoginCore(attributesCore));
    }
  };
};

function setProducts(products) {
  let prod = products.map((p) => {
    return { ...p, ...services[p.menu.menuId], menuId: p.menu.menuId };
  });
  prod = prod.filter((ar) => {
    return ar.icon !== undefined;
  });
  prod = removeDuplicates(prod, 'menuId');
  return prod;
}
async function setAtributeKushki(policyVigent, key, policy, dispatch) {
  const hasVDP = policy?.some((item) => item.product === menuCode.VIDA_DEVOLUCION);

  if (key && !hasVDP) {
    dispatch(
      createErrorPage({
        onAction: () => {},
        state: 'true',
        icon: 'broken',
        title: 'Error!',
        message: 'El usuario no tiene asociado el producto Vida Devolución Protecta',
      })
    );
  }
  const atributeKushki = {
    policyVigent: parseInt(policyVigent, 10),
    validateOTP: !isEmpty(key),
    key,
    useChangeCard: hasVDP,
    cardUpdated: false,
  };

  return atributeKushki;
}
function setProductPolicies(data) {
  const soatPolicies = data
    ?.find((p) => p.product === menuCode.SOAT)
    ?.policies?.filter(
      ({ salesChannel: { channel, status } }) => channel === 'Venta Directa' && status === 'ACTIVO'
    )
    .map((p) => formatStringDate(p?.issueDate));

  const removeDuplicatesItems = soatPolicies?.filter((item, index) => {
    return soatPolicies.indexOf(item) === index;
  });
  return removeDuplicatesItems || [];
}
function formatStringDate(dateOld) {
  return dateOld.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, '$3-$2-$1');
}
export const reducer = (state, action) => ({
  ...state,
  userInfo: action.payload,
});

export const reducerAttributesCore = (state, action) => ({
  ...state,
  attributesCore: action.payload,
});
