import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'loadsh/isEmpty';

import {
  createPaymentError,
  STATUS_PAYMENT_DEFAULT,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
} from '../../redux/auth/actions/payment-validation';
import { createErrorPage } from '../../redux/auth';
import { menuCode, policyFilterMessage } from '../../shared/constants/Strings';
import { getOptionsToProduct, getProductInfo } from '../../../util/Util';
import { Coverage } from '../../shared/constants/Coverage';
import { setStateProduct } from '../../redux/auth/actions/state-product';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Routes } from '../../../routes';

import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';

const img = 'product-VidaDevolucion';
const imgMobile = 'product-VidaDevolucion';

const headerTableFather = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

const getOptionsAvailable = () => {
  const options = [];

  if (
    process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_DEVOLUCION_DESCARGA_COMPROBANTE_PAGO === 'true'
  ) {
    options.push('DCP');
  }
  if (
    process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_DEVOLUCION_HISTORIAL_CAMBIO_TARJETA === 'true'
  ) {
    options.push('HCT');
  }
  if (process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_DEVOLUCION_MODIFICACION_TARJETA === 'true') {
    options.push('MDT');
  }
  if (process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_DEVOLUCION_DESCARGA_POLIZA === 'true') {
    options.push('DP');
  }
  if (process.env.REACT_APP_PERMISSION_PRODUCT_VIDA_DEVOLUCION_CUPONES_PENDIENTES === 'true') {
    options.push('CUP');
  }
  return options;
};

export default function HomeProductLifeDevolution(props) {
  const [loading, setLoading] = useState(false);
  const { userInfo, errorPayment } = useSelector((state) => state.auth);
  const { kushkiAtributes: { validateOTP, policyVigent } = {} } = userInfo;
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [currentCode, setCurrentCode] = useState(null);
  const [showModalKushki, setShowModalKushki] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
  const ga = React.useContext(GAProvider);
  const options = getOptionsAvailable();
  const {
    product,
    productDescription,
    policies
} = getProductInfo(userInfo?.productsDetail, menuCode.VIDA_DEVOLUCION);
  const [policyDefault, setPolicyDefault] = useState(null);
  const policiesMessage = policyFilterMessage.vidaDevolucion;

  const getComprobantePago = () => {
    const {
      nbranch,
      idProduct,
      nroPolicy: policy,
      nroCertificate: certificate,
    } = policies[currentIndex];
    const data = {
      'branch-id': parseInt(nbranch),
      'product-id': parseInt(idProduct),
      policy,
      certificate,
    };
    setParamsComprobantes(data);
  };

  useEffect(() => {
    if (currentIndex !== -1) {
      if (currentCode === 'DCP') {
        getComprobantePago();
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    switch (errorPayment) {
      case STATUS_PAYMENT_DEFAULT:
        return;
      case STATUS_PAYMENT_FAILED:
        dispatch(
          createErrorPage({
            state: 'true',
            icon: 'face',
            title: 'Error',
            message: 'No se pudo procesar su actualización de tarjeta',
            onAction: () => {},
          })
        );
        dispatch(createPaymentError(STATUS_PAYMENT_DEFAULT));
        break;
      case STATUS_PAYMENT_SUCCESS:
        dispatch(
          createErrorPage({
            state: 'true',
            icon: 'successFace',
            title: '¡Tarjeta actualizada!',
            message: 'Tu tarjeta fue actualizada correctamente.',
            onAction: () => {},
          })
        );
        dispatch(createPaymentError(STATUS_PAYMENT_DEFAULT));
        break;
      default:
    }
  }, [errorPayment]);

  const validatePolicy = () => {
    const existPolicy = policies.some((p) => p.nroPolicy === policyVigent);
    if (existPolicy) {
      setPolicyDefault(policyVigent);
    } else {
      dispatch(
        createErrorPage({
          state: true,
          message: `No se encontró la póliza ${policyVigent}`,
          icon: 'face',
          title: 'Error',
          onAction: () => {},
        })
      );
    }
  };

  useEffect(() => {
    if (errorPayment === STATUS_PAYMENT_DEFAULT) {
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto Vida Devolución Protecta`,
        action: 'click',
        label: 'El usuario visitó la página de Vida Devolución Protecta',
        value: 1,
      });
    }
  }, [ga]);


  useEffect(() => {
    if (validateOTP && policyVigent && !isEmpty(policies)) {
      dispatch(
        setStateProduct({
          menuDescription: productDescription,
          menuId: product, 
          policies: policies, 
        })
      );
      validatePolicy();
    }
  }, [validateOTP]);

  return (
    <div className="home-life-container">
      <PSBodyProduct
        questions={FrequentQuestions.SEGURO_DESGRAVAMEN}
        coverages={Coverage.SEGURO_DESGRAVAMEN}
        img={img}
        imgMobile={imgMobile}
        descriptionHeader={productDescription}
        iconHeader="productVDP"
        loading={loading}
        showMostConsulted={false}
        data={policies}
        options={getOptionsToProduct(options)}
        headerTable={headerTableFather}
        goBackFunction={() => history.push(Routes.HOME)}
        policyUpdated={policyDefault}
        onSelectItem={(item) => setCurrentIndex(item)}
        onSetCurrentCodeForm={setCurrentCode}
        paramsComprobantes={paramsComprobantes}
        menuId={product}
        useDigitalPolicy
        policiesMessage={policiesMessage}
        {...props}
      />
    </div>
  );
}
